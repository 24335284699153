import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { from } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import { MyHomeService } from 'src/app/my-home-page/service/my-home.service';
import { MyHomeState } from 'src/app/my-home-page/state/my-home.state';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { UploadService } from '../../service/upload.service';
import { ImageGalleryState } from '../../state/image-gallery.state';
import { ImageGalleryComponent } from '../image-gallery- modal/image-gallery.component';

@Component({
  selector: 'app-multiple-file-upload',
  templateUrl: './multiple-file-upload.component.html',
  styleUrls: ['./multiple-file-upload.component.scss'],
})
export class MultipleFileUploadComponent implements OnInit {
  @ViewChild('documentUploadDialog') documentUploadDialog!: TemplateRef<any>;
  @ViewChild('setDefaultImageDialog') setDefaultImageDialog!: TemplateRef<any>;
  @Input() inputType: any;
  alreadyUploadedImages: any[] = [];
  alreadyUploadedFloorImages: any[] = [];
  touched: boolean = false;
  isUploading: boolean = false;
  files: File[] = [];
  filesSelected?: FileList;
  type: any;
  message: any;
  uploadType: any;
  uploadedDocumentName: any;
  myForm: FormGroup;
  selectedFile: File | null = null;
  errorMessage: string | null = null;
  fileErrorMessage: string | null = null;
  uploadedFile: { name: string; url: string } | null = null;
  uploadedImageResponse: any[] = [];
  uploadedFilesResult: any;
  uploadedArrayResult: any;
  isModalOpen = false;
  inProgressDocumentUpload: any;
  selectedDefaultImage: any;
  imageBaseUrl = environment.mediaUrl + environment.s3Upload.project + '&ImageID=';
  @ViewChild('imageGalleryComponent') imageGalleryComponent!: ImageGalleryComponent;
  currentIndex = 0;

  constructor(
    private imgUpload: UploadService,
    private fb: FormBuilder,
    private myHomeState: MyHomeState,
    private myHomeService: MyHomeService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private imageGalleryState: ImageGalleryState
  ) { }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      name: ['', Validators.required],
      document: [null, Validators.required],
    });
    this.myHomeState.activeHome$.subscribe((res: any) => {
      this.uploadedFilesResult = res;
      this.alreadyUploadedImages = [];
      this.alreadyUploadedFloorImages = [];
      if (res) {
        this.uploadedArrayResult = res;
        if (res?.images) {
          res?.images.forEach((image) => {
            this.alreadyUploadedImages.push({
              url: this.imageBaseUrl + image,
              ImageID: image,
              uniqueId: uuidv4(),
            });
          });
        }
        if (res.floor) {
          res?.floor.forEach((image) => {
            this.alreadyUploadedFloorImages.push({
              url: this.imageBaseUrl + image,
              ImageID: image,
              uniqueId: uuidv4(),
            });
          });
        }
      }
    });
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  drop(event: CdkDragDrop<string[]>): void {
    this.touched = true;
    if (event.previousContainer === event.container) {
      // Move the item within the same container
      if (this.inputType == 'image') {
        moveItemInArray(this.alreadyUploadedImages, event.previousIndex, event.currentIndex);
        this.updateMyHome(this.alreadyUploadedImages);
      }

      if (this.inputType == 'floor') {
        moveItemInArray(this.alreadyUploadedFloorImages, event.previousIndex, event.currentIndex);
        this.updateMyHome(this.alreadyUploadedFloorImages);
      }
    } else {
      // Transfer the item from one container to another
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  async onDrop(event: DragEvent): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    this.filesSelected = event.dataTransfer.files;
    this.manageFileUpload();
  }

  async selectFiles(event: any): Promise<void> {
    this.filesSelected = event.target.files;
    this.manageFileUpload();
  }

  async manageFileUpload() {
    this.touched = true;
    this.message = [];
    let isHeicImagesUploaded = false;
    let selectedImagesCount = 0;
    let alreadyUploadedImagesCount = this.alreadyUploadedImages.length;
    let alreadyUploadedFloorCount = this.alreadyUploadedFloorImages.length;

    if (this.filesSelected && this.filesSelected[0]) {
      for (let i = 0; i < this.filesSelected.length; i++) {
        const file = this.filesSelected[i];
        let extension = file.type ? file.type.replace('image/', '').toLowerCase() : null;

        if (!extension) {
          const fileName = file.name;
          const fileExtensionMatch = fileName.match(/\.([a-zA-Z0-9]+)$/);
          if (fileExtensionMatch) {
            extension = fileExtensionMatch[1].toLowerCase();
          }
        }

        if (
          (this.inputType === 'floor' && ['jpeg', 'jpg', 'heic', 'png'].includes(extension)) ||
          (this.inputType === 'image' && ['jpeg', 'jpg', 'heic', 'png'].includes(extension))
        ) {
          if (this.inputType === 'image' || this.inputType === 'floor') {
            selectedImagesCount++;

            if (extension === 'heic') {
              isHeicImagesUploaded = true;
              this.isUploading = true;
              const heic2any = await import('heic2any');
              heic2any
                .default({ blob: file, toType: 'image/jpeg' })
                .then((convertedBlob: Blob) => {
                  const convertedReader = new FileReader();
                  convertedReader.onload = (e: any) => {
                    const preview = {
                      url: e.target.result,
                      ext: 'jpg',
                      project: 'PROSPECTOR',
                      env: 'DEV',
                      type: 'base64',
                      uniqueId: uuidv4(),
                      loading: true,
                    };
                    const isFloor = this.inputType === 'floor';
                    const selectedInputArray = isFloor ? this.alreadyUploadedFloorImages : this.alreadyUploadedImages;
                    const selectedInputArrayCount = isFloor ? alreadyUploadedFloorCount : alreadyUploadedImagesCount;
                    selectedInputArray.push(preview);
                    if (selectedInputArray.length - selectedInputArrayCount === selectedImagesCount) {
                      setTimeout(() => {
                        this.manageUpload();
                      }, 500);
                    }
                  };
                  convertedReader.readAsDataURL(convertedBlob);
                })
                .catch((error: any) => {
                  console.error('Conversion failed:', error);
                });
            } else {
              const reader = new FileReader();
              reader.onload = (e: any) => {
                const preview = {
                  url: e.target.result,
                  ext: extension,
                  project: 'PROSPECTOR',
                  env: 'DEV',
                  type: 'base64',
                  uniqueId: uuidv4(),
                  loading: true,
                };

                const isFloor = this.inputType === 'floor';
                const selectedInputArray = isFloor ? this.alreadyUploadedFloorImages : this.alreadyUploadedImages;
                const selectedInputArrayCount = isFloor ? alreadyUploadedFloorCount : alreadyUploadedImagesCount;
                selectedInputArray.push(preview);
                if (
                  selectedInputArray.length - selectedInputArrayCount === selectedImagesCount &&
                  !isHeicImagesUploaded
                ) {
                  setTimeout(() => {
                    this.manageUpload();
                  }, 500);
                }
              };
              reader.readAsDataURL(file);
            }
          }
        } else if (this.inputType === 'image' || this.inputType === 'floor') {
          this.openSnackBar(
            `${file.name} is not a valid image file. Only JPEG, JPG, PNG, HEIC formats are allowed.`,
            'snackbar-error'
          );
          // Show an error message for invalid file types in image mode
          // this.message.push(`${file.name} is not a valid image file. Only JPEG, JPG, PNG, HEIC formats are allowed.`);
          this.touched = true;
        }
      }

      // If no valid images were uploaded, trigger upload management if needed
      if (!selectedImagesCount) {
        this.manageUpload();
      }
    }
  }

  manageUpload() {
    if (!this.alreadyUploadedImages || (this.alreadyUploadedImages.length === 0 && this.inputType == 'image')) {
      return;
    }
    let alreadyUploadedInputArray =
      this.inputType === 'floor' ? this.alreadyUploadedFloorImages : this.alreadyUploadedImages;
    let imagesToUpload = alreadyUploadedInputArray.filter((element) => !element.ImageID);
    if (!imagesToUpload || imagesToUpload.length === 0) {
      return;
    }

    this.isUploading = true;
    from(imagesToUpload)
      .pipe(
        mergeMap(
          (element) => this.uploadFile(element),
          5 // Concurrency limit
        )
      )
      .subscribe({
        next: (res) => { },
        error: (err: any) => {
          this.isUploading = false;
          console.error('Error in uploads', err);
        },
        complete: () => {
          this.isUploading = false;
          console.log('All uploads complete');
          const isFloor = this.inputType === 'floor';
          let selectedInputArray = isFloor ? this.alreadyUploadedFloorImages : this.alreadyUploadedImages;
          this.updateMyHome(selectedInputArray);
        },
      });
  }

  // Function to handle the file upload
  uploadFile(preview: any) {
    let selectedInputArray = this.inputType === 'floor' ? this.alreadyUploadedFloorImages : this.alreadyUploadedImages;
    return this.imgUpload.uploadMultipleImage(preview).pipe(
      map((res) => {
        if (res && res.status === 200 && res.uniqueId) {
          selectedInputArray.forEach((element) => {
            if (element.uniqueId === res.uniqueId) {
              let path = null;
              if (res.ImageID != null) {
                path = res.ImageID;
              } else if (res.Key) {
                path = res.Key.replace(/^PROSPECTOR\//, '');
              }

              element['url'] = this.imageBaseUrl + path;
              element['ImageID'] = path;
              element.loading = false;
            }
          });
        } else {
          selectedInputArray.forEach((element) => {
            if (element.uniqueId === preview.uniqueId) {
              element.loading = false;
            }
          });
        }
        return res;
      }),

      catchError((err) => {
        selectedInputArray.forEach((element) => {
          if (element.uniqueId === preview.uniqueId) {
            element.loading = false;
          }
        });
        console.error('uploaded err:', err);
        throw err;
      })
    );
  }

  updateMyHome(value) {
    let myHomeValues = this.myHomeState.myHomeListValue.filter((myHome) => {
      return myHome.id == this.myHomeState.activeHomeValue.id;
    });

    let lImageIds = [];
    let selectedInputArray = this.inputType === 'floor' ? this.alreadyUploadedFloorImages : this.alreadyUploadedImages;
    selectedInputArray.forEach((element) => {
      if (element?.ImageID !== null && element?.ImageID != undefined) {
        lImageIds.push(element.ImageID);
      }
    });

    myHomeValues = myHomeValues[0];
    if (this.inputType == 'image') {
      myHomeValues.images = lImageIds;
    }
    if (this.inputType == 'floor') {
      myHomeValues.floor = lImageIds;
    }

    if (this.inputType == 'document') {
      if (!myHomeValues.document) {
        myHomeValues.document = [];
      }
      value[0].name = this.myForm.get('name').value;
      myHomeValues.document = [...myHomeValues.document, ...value];
      this.inProgressDocumentUpload = false;
      this.closeModal();
      this.myForm.reset();
    }
    this.myHomeService
      .updateMyHomeValues([myHomeValues], false)
      .pipe(first())
      .subscribe((res: any) => {
        this.isUploading = false;
      });
  }

  deleteImages(index) {
    this.touched = true;
    let myHomeValues = this.myHomeState.activeHomeValue;
    if (this.inputType == 'image') {
      myHomeValues.images.splice(index, 1);
    }
    if (this.inputType == 'floor') {
      myHomeValues.floor.splice(index, 1);
    }
    if (this.inputType == 'document') {
      myHomeValues.document.splice(index, 1);
    }
    this.myHomeService
      .updateMyHomeValues([myHomeValues], false)
      .pipe(first())
      .subscribe((res: any) => {
        this.openSnackBar('File deleted successfully', 'snackbar-success');
      });
  }

  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }

  selectedFiles = [];

  getFileExtension(file: File): string {
    const extension = file.type ? file.type.split('/').pop()!.toLowerCase() : '';
    if (!extension) {
      const fileName = file.name;
      const fileExtensionMatch = fileName.match(/\.([a-zA-Z0-9]+)$/);
      if (fileExtensionMatch) {
        return fileExtensionMatch[1].toLowerCase();
      }
    }
    return extension;
  }

  viewDocument(doc: { name: string; content: string | ArrayBuffer | null }): void {
    const newWindow = window.open();
    if (newWindow) {
      newWindow.document.write(
        `<iframe src="${doc.content}" frameborder="0" style="width:100%; height:100%;"></iframe>`
      );
    }
  }

  onFloorSelected(event: any): void {
    const files = event.target.files;
    this.errorMessage = null;
    // Ensure at least one file is selected
    if (files.length === 0) {
      this.errorMessage = 'Please select at least one file.';
      return;
    }
    this.selectedFiles = Array.from(files);
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file.type == 'image/tiff')
      this.openSnackBar(
        'TIFF files are currently not supported. Please select a different file format.',
        'snackbar-error'
      );

    this.errorMessage = null;
    const validDocumentMIMETypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'text/plain',
      'text/csv',
      'application/rtf',
      'image/jpeg',
      'image/jpg',
      'image/heic',
      'image/png',
    ];

    // Ensure at least one file is selected
    if (file && validDocumentMIMETypes.includes(file.type)) {
      this.selectedFile = file;
      this.myForm.patchValue({ document: file });
    } else {
      this.selectedFile = null;
      this.myForm.patchValue({ document: null });
      this.errorMessage = 'Document is required.';
    }
  }

  onSubmit(): void {
    if (this.myForm.invalid) return;
    if (this.myForm.valid && this.selectedFile) {
      this.inProgressDocumentUpload = true;
      this.imgUpload
        .uploadDocument(this.selectedFile, this.myForm.get('name').value)
        .pipe()
        .subscribe((res: any) => {
          if (res && res.data && res.data.Location) {
            this.updateMyHome([res.data]);
          }
        });
    } else {
      this.errorMessage = 'Please fill in all required fields.';
    }
  }

  downloadFile(url: string, name: string): void {
    if (url && name) {
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  openModal() {
    this.dialog.open(this.documentUploadDialog);
    this.myForm.reset();
    this.selectedFile = null;
  }
  setDefaultImageModal(selectedImage) {
    this.selectedDefaultImage = selectedImage;
    this.dialog.open(this.setDefaultImageDialog);
  }

  closeModal() {
    this.dialog.closeAll();
    this.selectedDefaultImage = null;
    this.myForm.reset();
    this.selectedFile = null;
  }

  setDefaultImage() {
    let myHomeValues = this.myHomeState.activeHomeValue;
    myHomeValues.defaultPropertyImage = this.selectedDefaultImage;
    this.myHomeService
      .updateMyHomeValues([myHomeValues], false)
      .pipe()
      .subscribe((res: any) => {
        console.log(res);
        if (res.isLoading == false) this.openSnackBar('Image updated Successfully', 'snackbar-success');
      });

    this.dialog.closeAll();
  }

  openMenu(event: MouseEvent, menu: MatMenuTrigger): void {
    event.stopPropagation(); // Prevent event from propagating to other elements
    menu.openMenu();
  }

  openGallery(index: number, type: string): void {
    let urlArray = [];
    if (type == 'image') {
      urlArray = this.alreadyUploadedImages.map((item) => item.url);
    } else {
      urlArray = this.alreadyUploadedFloorImages.map((item) => item.url);
    }
    this.imageGalleryState.imgGalleryCurrentIndex = index;
    this.imageGalleryState.imgGalleryListValue = urlArray;
  }

}
