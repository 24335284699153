import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import constants from 'src/constant.json';
import { environment } from '../../environments/environment';
import { VerifyLoginService } from '../verify-login/verify-login.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private http: HttpClient,
    private verifyLogInService: VerifyLoginService,
  ) { }

  userAuthenticate(userName, password): Observable<any> {
    return new Observable((observer) => {
      this.http.post(environment.prospektrBackendBaseURL + constants.API.userLogin, { userName, password }).subscribe(
        (res) => {
          observer.next(res);
          observer.complete();
        },
        (err) => {
          observer.error({
            status: false,
            message: err.error && err.error.message ? err.error.message : 'Incorrect username or password',
          });
        }
      );
    });
  }
}
