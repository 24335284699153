import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snackbar',
  template: `
    <span [innerHTML]="data.message"></span>
    <button class="ml-2 my-1" color="primary" mat-flat-button (click)="dismiss()">Dismiss</button>
  `,
  styleUrls: ['./custom-snackbar.component.scss'],
})
export class CustomSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<CustomSnackbarComponent>
  ) {}

  dismiss() {
    this.snackBarRef.dismiss();
  }
}
