import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportsState } from '../../../reports/reports.state';
@Component({
  selector: 'app-status-info',
  templateUrl: './status-info.component.html',
  styleUrls: ['./status-info.component.scss'],
})
export class StatusInfoComponent implements OnInit {
  requestStatus: any = '';
  constructor(
    private activatedRoute: ActivatedRoute,
    private reportState: ReportsState
  ) {

  }

  ngOnInit() {

    let onboardRequestStatus = history.state.data;
    if (onboardRequestStatus && onboardRequestStatus.res && onboardRequestStatus.res.status) {
      this.requestStatus = onboardRequestStatus.res.message;

    } else {
      this.requestStatus =
        onboardRequestStatus.error && onboardRequestStatus.error.message
          ? onboardRequestStatus.error.message
          : 'Request Failed';
    }
  }
}
