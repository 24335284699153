<!-- <div class="select-box" *ngIf="userRole == 'agent'">
  <div class="select-box-header">
    Primary Loan Officer<mat-icon
      class="Lo-select"
      matTooltip="Selected Loan officer will be responsible for the shared payment"
      matTooltipPosition="before"
      >info_outline</mat-icon
    >
  </div>
  <mat-form-field class="formField">
    <mat-select [formControl]="loanOfficer" (selectionChange)="getPlansByLoanOfficerId($event)">
      <mat-option *ngFor="let connectedLO of connectedLoanOfficers" value="{{ connectedLO._id }}"
        >{{ connectedLO.firstName }} {{ connectedLO.lastName }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div> -->
<mat-spinner *ngIf="loading" diameter="20" class="spinner"></mat-spinner>
<ng-container *ngIf="!loading">
  <div class="row w-100">
    <div class="col-md-6 col-12 col-sm-12" *ngFor="let plan of plans; let i = index">
      <!-- <mat-card
          class="card"
          [ngClass]="{
            'selected-card': connectedLoanOfficerId != null && onChanges == false
              ? connectedLoanOfficerId == loanOfficer.value && activePlanId == plan['id']
              : activePlanId == plan['id']
          }"
        > -->
      <!-- <mat-card
          class="card"
          [ngClass]="[
            connectedLoanOfficerId == loanOfficer.value && activePlanId == plan['id'] ? 'selected-card' : 'card'
          ]"
        > -->
      <mat-card class="card" [ngClass]="[activePlanId == plan['id'] ? 'selected-card' : 'card']">
        <div class="plan-box">
          <div class="plan-box-header">
            <!-- <h4 *ngIf="plan?.interval == 'month'">Pay Monthly</h4>
              <h4 *ngIf="plan?.interval != 'month'">Pay Per Report</h4> -->
            <div class="plan-box-header1">
              <h4>{{ plan.product.replace('ValueTest', '') }}</h4>
              <div class="currency-s">
                <span class="currency">{{
                  plan['discountPrice'] ? (plan['discountPrice'] | currency) : (plan['amount'] | currency)
                }}</span
                >{{ plan?.interval == 'month' ? '/Month' : '/Report' }}
              </div>
            </div>

            <div>
              <!-- <button
                  class="choose-plan-btn"
                  [ngClass]="[
                    connectedLoanOfficerId == loanOfficer.value && activePlanId == plan['id']
                      ? 'selected-choose-plan-btn'
                      : 'choose-plan-btn'
                  ]"
                  (click)="choosePlan(plan['id'])"
                > -->
              <button
                class="choose-plan-btn"
                [ngClass]="[activePlanId == plan['id'] ? 'selected-choose-plan-btn' : 'choose-plan-btn']"
                (click)="choosePlan(plan['id'])"
              >
                <!-- {{
                    connectedLoanOfficerId == loanOfficer.value && activePlanId == plan['id']
                      ? 'Selected Plan'
                      : 'Choose Plan'
                  }} -->
                {{ activePlanId == plan['id'] ? 'Selected Plan' : 'Choose Plan' }}
              </button>
            </div>
          </div>
          <div class="plan-box-content">
            <ul class="features-list">
              <li *ngFor="let feature of plan?.['features']" class="features">
                <mat-icon class="done-icon">done</mat-icon>
                <span>{{ feature.name }}</span>
              </li>
            </ul>
          </div>
          <div class="promotion-code-section" *ngIf="plan?.['offerApplied'] != true && plan.amount ">
            <span>Promo code:</span>
            <span><input type="text" [formControl]="code" placeholder="Enter Promo Code" class="input-class" /></span>
            <span
              ><button (click)="applyCode(plan['id'], i)" class="apply-btn" [disabled]="spin">
                <i class="fa fa-spinner fa-spin" *ngIf="spin && currentIndex == i"> </i>
                Apply
              </button></span
            >
          </div>
          <div class="promotion-code-section" *ngIf="plan?.['offerApplied'] == true">
            <span>{{ plan?.['promoCode'] }} Applied</span>
            <span><button class="apply-btn" (click)="removeCode(plan['id'])">Remove</button></span>
          </div>
          <span class="warning-msg" *ngIf="nextPlanId == plan['id']">
            <mat-icon class="Lo-select">info_outline</mat-icon>
            <p>
              This plan will take effect only after your current subscription,{{ activePlanName }}, ends on
              {{ nextPlanStartDate }}
            </p>
          </span>
        </div>
      </mat-card>
    </div>
  </div>
</ng-container>
<div class="button-section">
  <div class="info-section"></div>
  <button
    mat-flat-button
    color="primary"
    class="save-plan"
    (click)="save()"
    [disabled]="loading"
    *ngIf="userRole != 'appraiser'"
  >
    Save Plan
  </button>
</div>
