import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { UserState } from '../state/user.state';

@Injectable({
  providedIn: 'root',
})
export class RedirectionGuard implements CanActivate {
  constructor(private router: Router, private appState: AppState, private userState: UserState) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.appState.companyInfo$.pipe(
      take(1), // Automatically unsubscribes after the first emission
      map((companyInfo) => {

        const userRole = this.userState.getUserRoleValue;
        if (companyInfo?.appConfig?.valueReport?.isEnabled) {
          return this.handleValueReportRoute(route, companyInfo, userRole);
        } else if (companyInfo?.appConfig?.opportunityScanner?.isEnabled) {
          return this.handleOpportunityScannerRoute(route, companyInfo, userRole);
        } else {
          return true; // Default case
        }
      }),
      catchError(() => {
        this.router.navigate(['not-found']); // Navigate to an error page if something goes wrong
        return of(false);
      })
    );
  }

  private handleValueReportRoute(route: ActivatedRouteSnapshot, companyInfo: any, userRole: string): boolean {
    if (this.appState.authTokenValue?.idToken && userRole) {

      const allowedRoutes = companyInfo.appMenuConfig
        .filter((menu) => menu.roles.includes(userRole))
        .map((menu) => menu.routePath.replace(/\//g, ''));

      if (allowedRoutes.length > 0) {
        const currentPath = route?.url?.[0]?.path;
        if (currentPath && allowedRoutes.includes(currentPath)) {
          return true;
        } else {
          this.router.navigate([allowedRoutes[0]]);
          return false;
        }
      } else {
        this.router.navigate(['signup/user']);
        return false;
      }
    } else {
      this.router.navigate(['signup/user']);
      return false;
    }
  }

  private handleOpportunityScannerRoute(route: ActivatedRouteSnapshot, companyInfo: any, userRole: string): boolean {
    if (this.appState.authTokenValue?.idToken && userRole) {
      const allowedRoutes = companyInfo.appMenuConfig
        .filter((menu) => menu.roles.includes(userRole))
        .map((menu) => menu.routePath.replace(/\//g, ''));

      const currentPath = route?.url?.[0]?.path;

      if (currentPath && allowedRoutes.includes(currentPath)) {
        return true;
      }

      const hasMapRedirect = route?.routeConfig?.children?.some(
        (child) => child.redirectTo === 'map' || child.path === 'map'
      );

      if (hasMapRedirect) {
        return true;
      } else if (allowedRoutes.length > 0) {
        this.router.navigate([allowedRoutes[0]]);
      } else {
        this.router.navigate(['login']);
      }

      return false;
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }

}
