import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerPageComponent } from './modal-container/component/container-page/container-page.component';
import { OnboardRequestComponent } from './onboard-request/component/onboard-request/onboard-request.component';
import { DealAnalysisSheetComponent } from './_shared/component/deal-analysis-sheet/deal-analysis-sheet.component';
import { CorporateLoginDeactivationGuard } from './_shared/guard/corporate-login-deactivation.guard';
import { NotFoundDeactivationGuard } from './_shared/guard/not-found-deactivation.guard';
import { RedirectionGuard } from './_shared/guard/redirection.guard';
import { AuthenticationResolver } from './_shared/resolver/authentication.resolver';

const routes: Routes = [
  { path: '', redirectTo: 'workspace', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    // canActivate: [SSOAuthGuard],
  },
  {
    path: 'login/:userId',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    // canActivate: [SSOAuthGuard],
  },
  {
    path: 'sso/:accesstoken',
    loadChildren: () => import('./verify-login/verify-login.module').then((m) => m.VerifyLoginModule),
    resolve: { AuthenticationResolver },
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    resolve: { AuthenticationResolver },
    canActivate: [RedirectionGuard],
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
    resolve: { AuthenticationResolver },
    canActivate: [RedirectionGuard],
  },
  {
    path: 'valueReport',
    loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
    resolve: { AuthenticationResolver },
    canActivate: [RedirectionGuard],
  },
  {
    path: 'opportunityScanner',
    loadChildren: () =>
      import('./opportunity-scanner/opportunity-scanner.module').then((m) => m.OpportunityScannerModule),
    resolve: { AuthenticationResolver },
    canActivate: [RedirectionGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    // canActivate: [LogInGuard],
    resolve: { AuthenticationResolver },
  },
  {
    path: 'workspace',
    loadChildren: () => import('./workspace/workspace.module').then((m) => m.WorkspaceModule),
    resolve: { AuthenticationResolver },
    canActivate: [RedirectionGuard],
  },

  {
    path: 'portfolio/:userId',
    loadChildren: () => import('./portfolio/portfolio.module').then((m) => m.PortfolioModule),
    resolve: { AuthenticationResolver },
  },

  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then((m) => m.SignupModule),
    // canActivate: [SSOAuthGuard],
  },
  {
    path: 'signup/continue/:identifier',
    loadChildren: () => import('./signup/signup.module').then((m) => m.SignupModule),
    //canActivate: [SSOAuthGuard],
  },
  {
    path: 'signup/:userId',
    loadChildren: () => import('./signup/signup.module').then((m) => m.SignupModule),
    //  canActivate: [SSOAuthGuard],
  },
  {
    path: 'trialSignup',
    loadChildren: () => import('./signup/signup.module').then((m) => m.SignupModule),
    //  canActivate: [SSOAuthGuard],
  },
  {
    path: 'verifyAccount/:id',
    loadChildren: () => import('./verify-signup/verify-signup.module').then((m) => m.VerifySignupModule),
    // canActivate: [SSOAuthGuard],
  },
  {
    path: 'forgotPassword',
    loadChildren: () => import('./forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
    // canActivate: [SSOAuthGuard],
  },
  {
    path: 'forgotPassword/:userId',
    loadChildren: () => import('./forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
    // canActivate: [SSOAuthGuard],
  },
  {
    path: 'resetPassword/:userResetToken',
    loadChildren: () => import('./forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
    //canActivate: [SSOAuthGuard],
  },
  {
    path: 'watchlist',
    loadChildren: () =>
      import('./followed-properties/followed-properties.module').then((m) => m.FollowedPropertiesModule),
    resolve: { AuthenticationResolver },
  },
  {
    path: 'savedSearches',
    loadChildren: () => import('./saved-searches/saved-searches.module').then((m) => m.SavedSearchesModule),
    resolve: { AuthenticationResolver },
  },

  {
    path: 'agent',
    loadChildren: () => import('./agent/agent.module').then((m) => m.AgentModule),
    resolve: { AuthenticationResolver },
  },
  {
    path: 'recommendations',
    loadChildren: () => import('./recommendation/recommendation.module').then((m) => m.RecommendationModule),
    resolve: { AuthenticationResolver },
  },
  {
    path: 'notification',
    loadChildren: () => import('./notifications/notification.module').then((m) => m.NotificationModule),
    resolve: { AuthenticationResolver },
  },
  {
    path: 'accountExpired',
    loadChildren: () =>
      import('./account-upgrading-reminder/account-upgrading-reminder.module').then(
        (m) => m.AccountUpgradingReminderModule
      ),
    // canActivate: [SSOAuthGuard],
    resolve: { AuthenticationResolver },
  },
  {
    path: 'alerts',
    loadChildren: () => import('./property-alerts/property-alerts.module').then((m) => m.PropertyAlertsModule),
    resolve: { AuthenticationResolver },
  },
  {
    path: 'upgradeAccount/:id',
    loadChildren: () => import('./upgrade-account/upgrade-account.module').then((m) => m.UpgradeAccountModule),
    // canActivate: [SSOAuthGuard],
  },
  {
    path: 'dealAnalysis',
    component: DealAnalysisSheetComponent,
    resolve: { AuthenticationResolver },
  },
  {
    path: 'info/requestStatus',
    component: ContainerPageComponent,
  },
  {
    path: 'campaigns',
    loadChildren: () => import('./campaigns/campaigns.module').then((m) => m.CampaignsModule),
    resolve: { AuthenticationResolver },
  },
  {
    path: 'inboundRedirect',
    loadChildren: () => import('./inbound-request/inbound-request.module').then((m) => m.InboundRequestModule),
    resolve: { AuthenticationResolver },
  },
  {
    path: 'updateOnboardRequest/:identifier',
    component: OnboardRequestComponent,
  },
  {
    path: 'deals',
    loadChildren: () => import('./deal-analysis/deal-analysis.module').then((m) => m.DealAnalysisModule),
    resolve: { AuthenticationResolver },
  },
  {
    path: 'myClients',
    loadChildren: () => import('./client-dashboard/client-dashboard.module').then((m) => m.ClientDashboardModule),
    resolve: { AuthenticationResolver },
    canActivate: [RedirectionGuard],
  },
  {
    path: 'my-property',
    loadChildren: () => import('./my-home-page/my-home-page.module').then((m) => m.MyHomePageModule),
    resolve: { AuthenticationResolver },
    canActivate: [RedirectionGuard],
  },
  {
    path: 'subscription',
    loadChildren: () => import('./subscription-plan/subscription-plan.module').then((m) => m.SubscriptionPlanModule),
    resolve: { AuthenticationResolver },
  },
  {
    path: 'corporate-login',
    loadChildren: () => import('./corporate-login/corporate-login.module').then((m) => m.CorporateLoginModule),
    // canActivate: [CorporateLoginActivationGuard],
    canDeactivate: [CorporateLoginDeactivationGuard],
  },
  {
    path: 'not-found',
    loadChildren: () => import('./page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
    canDeactivate: [NotFoundDeactivationGuard],
  },
  { path: '**', redirectTo: 'workspace' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
