import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportsState {
  private reportsListSub$ = new BehaviorSubject<Array<any>>([]);
  reportsList = this.reportsListSub$.asObservable();
  public get reportsListValue(): Array<any> {
    return this.reportsListSub$.value;
  }
  public set reportsListValue(recommendedData: Array<any>) {
    this.reportsListSub$.next(recommendedData);
  }

  private reportsSub$ = new BehaviorSubject<Array<any>>([]);
  reports = this.reportsSub$.asObservable();
  public get reportsValue(): Array<any> {
    return this.reportsSub$.value;
  }
  public set reportsValue(recommendedData: Array<any>) {
    this.reportsSub$.next(recommendedData);
  }

  private reportsCountSub$ = new BehaviorSubject<number>(0);
  reportsCount = this.reportsCountSub$.asObservable();
  public get reportsCountValue(): number {
    return this.reportsCountSub$.value;
  }
  public set reportsCountValue(count: number) {
    this.reportsCountSub$.next(count);
  }

  private openCreateReportSheetSub$ = new BehaviorSubject<boolean>(false);
  openCreateReportSheet$ = this.openCreateReportSheetSub$.asObservable();
  public get openCreateReportSheetValue(): boolean {
    return this.openCreateReportSheetSub$.value;
  }
  public set openCreateReportSheetValue(status: boolean) {
    this.openCreateReportSheetSub$.next(status);
  }

  private showPropertyDetailsCardSub$ = new BehaviorSubject<boolean>(false);
  showPropertyDetailsCard$ = this.showPropertyDetailsCardSub$.asObservable();
  public get showPropertyDetailsCardValue(): boolean {
    return this.showPropertyDetailsCardSub$.value;
  }
  public set showPropertyDetailsCardValue(status: boolean) {
    this.showPropertyDetailsCardSub$.next(status);
  }

  private isLoadingCreateReport$ = new BehaviorSubject<boolean>(false);
  isLoadingCreateReportValue = this.isLoadingCreateReport$.asObservable();
  public get isLoadingCreateReport(): boolean {
    return this.isLoadingCreateReport$.value;
  }
  public set isLoadingCreateReport(status: boolean) {
    this.isLoadingCreateReport$.next(status);
  }

  private isLoadingReport$ = new BehaviorSubject<boolean>(false);
  isLoadingReportValue = this.isLoadingReport$.asObservable();
  public get isLoadingReport(): boolean {
    return this.isLoadingReport$.value;
  }
  public set isLoadingReport(status: boolean) {
    this.isLoadingReport$.next(status);
  }

  private isDownloadingReport$ = new BehaviorSubject<boolean>(false);
  isDownloadingReportValue = this.isDownloadingReport$.asObservable();
  public get isDownloadingReport(): boolean {
    return this.isDownloadingReport$.value;
  }
  public set isDownloadingReport(status: boolean) {
    this.isDownloadingReport$.next(status);
  }

  private listPropertySetCount$ = new BehaviorSubject<any>(null);
  listPropertySetCountValue = this.listPropertySetCount$.asObservable();
  public get listPropertySetCount(): any {
    return this.listPropertySetCount$.value;
  }
  public set listPropertySetCount(propertyList: any) {
    this.listPropertySetCount$.next(propertyList);
  }

  private listPropertySetSub$ = new BehaviorSubject<any>(null);
  listPropertySet$ = this.listPropertySetSub$.asObservable();
  public get listPropertySetValue(): any {
    return this.listPropertySetSub$.value;
  }
  public set listPropertySetValue(propertyList: any) {
    this.listPropertySetSub$.next(propertyList);
  }

  private createReportResponse$ = new BehaviorSubject<any>(null);
  createReportResponse = this.createReportResponse$.asObservable();
  public get createReportResponseValue(): any {
    return this.createReportResponse$.value;
  }
  public set createReportResponseValue(response: any) {
    this.createReportResponse$.next(response);
  }

  private propertyDetailsCardInput$ = new BehaviorSubject<any>(null);
  propertyDetailsCardInput = this.propertyDetailsCardInput$.asObservable();
  public get propertyDetailsCardValue(): any {
    return this.propertyDetailsCardInput$.value;
  }
  public set propertyDetailsCardValue(propertyList: any) {
    this.propertyDetailsCardInput$.next(propertyList);
  }

  private searchPropertyInput$ = new BehaviorSubject<any>(null);
  searchPropertyInput = this.searchPropertyInput$.asObservable();
  public get searchPropertyValue(): any {
    return this.searchPropertyInput$.value;
  }
  public set searchPropertyValue(propertyList: any) {
    this.searchPropertyInput$.next(propertyList);
  }

  private searchAddressSub$ = new BehaviorSubject<any>(null);
  searchAddress$ = this.searchAddressSub$.asObservable();
  public get searchAddressValue() {
    return this.searchAddressSub$.getValue();
  }
  public set searchAddressValue(address: any) {
    this.searchAddressSub$.next(address);
  }

  private valueReportPdf$ = new BehaviorSubject<any>(null);
  valueReportPdf = this.valueReportPdf$.asObservable();
  public get valueReportPdfUrl(): any {
    return this.valueReportPdf$.value;
  }
  public set valueReportPdfUrl(propertyList: any) {
    this.valueReportPdf$.next(propertyList);
  }

  private isPaymentCardAdded$ = new BehaviorSubject<boolean>(true);
  isPaymentCardAddedValue = this.isPaymentCardAdded$.asObservable();
  public get isPaymentCardAdded(): boolean {
    return this.isPaymentCardAdded$.value;
  }
  public set isPaymentCardAdded(propertyList: boolean) {
    this.isPaymentCardAdded$.next(propertyList);
  }

  private filterValueSub$ = new BehaviorSubject<any>(null);
  filterValue$ = this.filterValueSub$.asObservable();
  public get filterValValue() {
    return this.filterValueSub$.getValue();
  }
  public set filterValValue(address: any) {
    this.filterValueSub$.next(address);
  }

  private mlsAddShownSub$ = new BehaviorSubject<boolean>(false);
  mlsAddShown$ = this.mlsAddShownSub$.asObservable();
  public get mlsAddShown(): boolean {
    return this.mlsAddShownSub$.getValue();
  }
  public set mlsAddShown(mlsAdded: boolean) {
    this.mlsAddShownSub$.next(mlsAdded);
  }

  private reportTypeSub$ = new BehaviorSubject<any>(null);
  reportType$ = this.reportTypeSub$.asObservable();
  public get reportTypeValue() {
    return this.reportTypeSub$.getValue();
  }
  public set reportTypeValue(address: any) {
    this.reportTypeSub$.next(address);
  }

  private openScheduleReportSheetSub$ = new BehaviorSubject<boolean>(false);
  openScheduleReportSheet$ = this.openScheduleReportSheetSub$.asObservable();
  public get openScheduleReportSheetValue(): boolean {
    return this.openScheduleReportSheetSub$.value;
  }
  public set openScheduleReportSheetValue(status: boolean) {
    this.openScheduleReportSheetSub$.next(status);
  }

  private statiticsDataSub$ = new BehaviorSubject<Array<any>>([]);
  statisticsData$ = this.statiticsDataSub$.asObservable();
  public get statisticsDataListValue(): Array<any> {
    return this.statiticsDataSub$.value;
  }
  public set statisticsDataListValue(statiticsData: Array<any>) {
    this.statiticsDataSub$.next(statiticsData);
  }

  private statiticsTotalCountSub$ = new BehaviorSubject<number>(0);
  statiticsTotalCount$ = this.statiticsTotalCountSub$.asObservable();
  public get statiticsTotalCountValue(): number {
    return this.statiticsTotalCountSub$.value;
  }
  public set statiticsTotalCountValue(value: number) {
    this.statiticsTotalCountSub$.next(value);
  }


  private myReportSub$ = new BehaviorSubject<any>({});
  myReport$ = this.myReportSub$.asObservable();

  public get reportIdValue() {
    return this.myReportSub$.value;
  }

  public set reportIdValue(reportId) {
    this.myReportSub$.next(reportId);
  }
}
